<template>
	<section class="stay bg-mono-lightest py-9">
		<div class="container">
			<div class="row align-items-center flow-large flow-lg-none">
				<div class="col-0 col-lg-1"></div>

				<div class="col-12 col-lg">
					<div class="flow-loose">
						<h2 class="family-heading font-large">
							Stay
						</h2>

						<div class="clamp-medium flow-medium pe-7">
							<p>Woodlands at Hothorpe Hall has around 16 rooms available, from <span class="weight-medium">£150-£220</span> per night. This includes breakfast on the Saturday morning.</p>
							
							<p>To secure a room, book your accommodation as quickly as possible to avoid (some) disappointment.</p>

							<p class="font-base">Please use the passcode: <strong>1408</strong>.</p>

							<div>
								<a class="button bg-brand-accent color-mono-lightest px-4" target="_blank" rel="nofollow noreferrer" href="https://www.tickettailor.com/events/thewoodlands/788752">Book now</a>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg flow-loose">
					<div class="stay-img">
						<img loading="lazy" :src="require( '../assets/images/stay.jpg' )" alt="Image of the Woodlands at Hothorpe">
					</div>
				</div>

				<div class="col-0 col-lg-1"></div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Stay'
	}
</script>

<style lang="scss" scoped>
	.stay-img {
		position: relative;

		img {
			position: relative;
			z-index: 1;
			border-right: map-get( $spacers, 2 ) solid white; 
			border-bottom: map-get( $spacers, 2 ) solid white; 
		}

		&:after {
			content: '';
			background-color: var( --mono-base );
			height: 100%;
			width: 100%;
			position: absolute;
			top: map-get( $spacers, 2 );
			left: map-get( $spacers, 2 );
		}
	}
</style>