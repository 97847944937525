<template>
	<router-view/>
</template>

<style lang="scss">
	body {
		margin: 0;
	}

	main {
		// overflow: hidden;
	}
</style>