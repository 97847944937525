<template>
	<header class="header" id="masthead">
		<div class="container">
			<nav>
				<ul class="row align-items-center ps-0 py-3" role="list">
					<li class="col-auto pe-2 pe-md-5">
						<a href="#information">Info</a>
					</li>
					<li class="col-auto pe-2 pe-md-5">
						<a href="#order">Order</a>
					</li>
					<li class="col-auto pe-2 pe-md-5">
						<a href="#stay">Stay</a>
					</li>
					<li class="col-auto pe-2 pe-md-5">
						<a href="#rsvp">RSVP</a>
					</li>
					<li class="col-auto pe-2 pe-md-5">
						<a href="#gifts">Gifts</a>
					</li>
				</ul>
			</nav>
		</div>
	</header>
</template>

<script>
	import debounce from 'lodash/debounce';

	export default {
		name: "Header",

		data() {
			return {
				handleDebouncedScroll: null
			};
		},

		methods: {
			handleScroll: function() {
				let sections = document.querySelectorAll( '#app section' );
				let window_pos = window.scrollY;

				sections.forEach( section => {
					let section_top = section.getBoundingClientRect().top;

					if ( ( window_pos - ( section_top + section.clientHeight ) ) >= 0 ) {
						if ( section.classList.contains( 'hero' ) ) {
							document.getElementById( 'masthead' ).classList.remove( 'bg-mono-lightest' );
						} else {
							document.getElementById( 'masthead' ).classList.add( 'bg-mono-lightest' );
						}
					}

					if ( ( window_pos <= window.clientHeight ) || window_pos <= sections[0].clientHeight ) {
						document.getElementById( 'masthead' ).classList.remove( 'bg-mono-lightest' );	
					}
				} );
			}
		},

		mounted() {
			this.handleDebouncedScroll = debounce( this.handleScroll, 25 );
			window.addEventListener( 'scroll', this.handleDebouncedScroll );
		},

		beforeUnmount() {
			window.removeEventListener( 'scroll', this.handleDebouncedScroll );
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		position: fixed;
		z-index: 5;
		top: 0;
		left: 0;
		width: 100%;
		transition: background 333ms ease-in-out;

		&:not(.bg-mono-lightest) {
			@include media-breakpoint-down(xl) {
				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					box-shadow: 0 20px 100px rgba( 0, 0, 0, .66);
					transform: translateY(-100%);
				}
			}

			@include media-breakpoint-down(xl) {
				a {
					color: var( --mono-lightest );
				}
			}
		}
	}

	a {
		text-transform: uppercase;
		font-weight: var( --weight-medium );
		font-size: var( --font-small );
		color: var( --mono-darker );

		&:hover {
			color: var( --mono-darkest );
		}
	}
</style>