<template>
	<Header />

	<main>
		<Hero />
		<Information id="information" />
		<Order id="order" />
		<Stay id="stay" />
		<RSVP id="rsvp" />
		<Gifts id="gifts" />
		<Gallery />

		<footer class="py-3 bg-mono-lightest">
			<div class="container">
				Built by <a class="weight-medium" href="https://www.kaluna.co.uk" rel="nofollow noreferrer" target="_blank">Kaluna</a> (obviously).
			</div>
		</footer>
	</main>

	<!-- <Footer /> -->
</template>

<script>
	import Hero from '@/components/Hero.vue';

	export default {
		name: 'Home',
		
		components: {
			Hero
		}
	}
</script>