<template>
	<section class="information bg-mono-lightest py-9">
		<div class="container">
			<div class="row">
				<div class="col-0 col-lg-2"></div>

				<div class="col-12 col-lg-6 flow-medium font-mid pe-lg-9">
					<h1 class="font-large family-heading color-mono-darkest clamp-thin clamp-md-none">
						You are invited To our wedding day
					</h1>

					<p>To our friends and family, we're so excited to celebrate our wedding day with you.</p>

					<div class="font-base flow-medium">
						<p>We are planning for the ceremony to be held outside, so please dress with the British weather in mind.</p>

						<p>Guests can arrive anytime from <strong>12:00pm</strong>, but please be with us by <strong>12:30pm</strong> in time for a <strong>1:00pm</strong> start.</p>
					</div>
				</div>

				<div class="col-0 col-lg-4 information-svg__parent">
					<inline-svg class="information-svg fill-mono-dark" viewBox="0 0 80.447 401" :src="require( '@/assets/svgs/flower-information.svg' )"></inline-svg>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Information'
	}
</script>

<style lang="scss" scoped>
	.information-svg__parent {
		position: relative;
	}

	.information-svg {
		position: absolute;
		top: -25%;
		left: 0;
		height: 50vh;
		width: auto;
	}
</style>
