<template>
	<section class="gallery py-7">
		<div class="container">
			<div class="row">
				<div v-for="( image, index ) in images" :key="index" class="col-6 col-lg-3 gallery-image__parent">
					<img class="gallery-image mb-4" :src="require( '@/assets/images/' + image.src )" :alt="image.alt">
				</div>
			</div>
		</div>

		<div class="gallery-bg bg-mono-light"></div>
	</section>
</template>

<script>
	import data from '@/assets/data/gallery.json';

	export default {
		name: 'Gallery',

		data() {
			return {
				images: data
			}
		}
	}
</script>

<style lang="scss" scoped>
	.gallery {
		position: relative;
	}

	.gallery-bg {
		position: absolute;
		z-index: -1;
		bottom: 0;
		height: 66%;
		width: 100%;
		left: 0;
	}

	.gallery-image {
		aspect-ratio: 1/1.33;
		object-fit: cover;
		box-shadow: 0 0 33px rgba( 0, 0, 0, 0.16 );
	}

	@include media-breakpoint-up(lg) {
		.gallery-image__parent {
			&:nth-of-type(even) {
				margin-top: -2rem;
			}
		}
	}
</style>