import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import components from '@/components';
import svg from 'vue-inline-svg';

// create the app.
const app = createApp(App);

// use stuff.
app.use(store);
app.use(router);

// inline svgs.
app.component('inline-svg', svg);

// import all components.
Object.values( components ).forEach( component => {
    app.component(component.name, component);
} );

// mount the app.
app.mount('#app');
