<template>
	<section class="order bg-mono-light">
		<div class="order-inner py-9">
			<div class="container flow-loose">
				<div class="text-center flow-medium mb-7">
					<h2 class="font-large family-heading">
						Order of the day
					</h2>

					<p>Please note, times are estimates. If you'd have met us—that would be obvious.</p>
				</div>

				<ul class="order-list flow-tight p-0 margin-inline clamp-medium m-auto" role="list">
					<li v-for="( event, index ) in events" :key="index">
						<div class="row align-items-baseline">
							<div class="col-3 family-heading font-lead me-5">
								<span v-html="event.time"></span>
							</div>

							<div class="col font-mid">
								<span v-html="event.description"></span>
							</div>
						</div>
					</li>
				</ul>
			</div>
			
			<inline-svg class="order-diamond fill-mono-dark" viewBox="0 0 18.598 25" :src="require( '@/assets/svgs/diamond.svg' )"></inline-svg>	
		</div>
	</section>
</template>

<script>
	import data from '@/assets/data/order.json';

	export default {
		name: 'Order',

		data() {
			return {
				events: data
			}
		}
	}
</script>

<style lang="scss" scoped>
	.order-inner {
		position: relative;
	}

	.order .container {
		position: relative;
		z-index: 1;
	}

	.order-diamond {
		position: absolute;
		z-index: 0;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		height: 120%;
		width: auto;
		opacity: 0.33;
		max-width: 90%;
	}
</style>