<template>
	<section class="rsvp bg-mono-lightest py-9">
		<div class="container">
			<div class="row flow-loose flow-lg-none">
				<div class="col-12 col-lg-4 flow-medium">
					<h2 class="font-large family-heading color-mono-darkest clamp-thin clamp-md-none">Let us know you're attending</h2>

					<p>Please RSVP before <strong>Friday 20th January 2023</strong>.</p>

					<p>Any problems, please contact us via <a class="weight-medium" href="mailto:rsvp@naomiandmatty.co.uk?subject=Help me!">rsvp@naomiandmatty.co.uk</a> etc.</p>
				</div>

				<div class="col-lg-1"></div>

				<div class="col-12 col-lg">
					<form @submit.prevent="rsvp" class="bg-mono-light p-5 flow-loose" name="rsvp" action="/thanks" method="POST" data-netlify="true">
						<p class="font-base weight-medium color-state-danger">(*) required field.</p>

						<fieldset class="mt-4">
							<legend>
								Your information
							</legend>

							<p class="description font-small mb-3">Type your name as it is written on your invite, and we'll find you and your party.</p>

							<div class="row form-row">
								<div class="col-12 col-lg-6">
									<label for="your_name">
										<span>First name <span class="color-state-danger">(*)</span></span>
									</label>

									<input :disabled="guest_found === true" required @input="find_guest" type="text" v-model="guest.first_name" id="your_name">
								</div>

								<div class="col-12 col-lg-6">
									<label for="your_surname">
										<span>Surname <span class="color-state-danger">(*)</span></span>
									</label>

									<input :disabled="guest_found === true" required @input="find_guest" type="text" v-model="guest.surname" id="your_surname">
								</div>

								<div v-if="true === guest_found">
									<button class="font-small weight-bold color-state-danger" type="button" @click.prevent="reset">Reset</button>
								</div>

								<div class="col-12" v-if="true === loading">
									<Loader text="Giz a mo" />
								</div>

								<div v-if="false === guest_found" class="color-state-danger weight-medium">
									Sorry, we couldn't find you. Give us a shout as it's probably something Matty has done wrong. 
								</div>

								<div v-if="( false !== guest_found ) && ( guest.first_name && guest.first_name.length >= 3 ) && ( guest.surname && guest.surname.length >= 3 )" class="col-12">
									<label for="your_email">
										<span>Email address <span class="color-state-danger">(*)</span></span>
									</label>

									<input required type="email" v-model="guest.email" id="your_email">
								</div>
							</div>
						</fieldset>

						<fieldset v-if="typeof guest.group !== 'undefined' && Object.values( guest.group ).length > 0">
							<legend>
								Attendees
							</legend>

							<p class="description font-small mb-3">Respond on behalf of you and your party.</p>

							<div class="flow-medium">
								<div class="guest-row" v-for="( guestie, index ) in guest.group" :key="index">
									<div class="row align-items-center" :class="true === guestie.open && typeof guestie.child === 'undefined' ? 'pb-4' : 'pb-0'">
										<div class="col-12">
											<button :aria-label="'Toggle the visibility of ' + guestie.first_name + '\'s options'" :title="'Toggle the visibility of ' + guestie.first_name + '\'s options'" type="button" @click.prevent="guestie.open = !guestie.open" class="guest-toggle family-heading font-mid row g-0 justify-content-between align-items-center mb-1" :class="true === guestie.open ? 'color-mono-darkest' : 'color-mono-darker'">
												<span class="col-auto">
													{{ guestie.first_name }} {{ guestie.surname }}
												</span>

												<span class="times col-auto" :class="true === guestie.open ? 'guest-open' : 'guest-closed'"></span>
											</button>
										</div>

										<div v-if="guestie.open === true" class="row m-0 p-0">
											<div class="col-12 col-xl">
												<label :for="'guest-' + guestie.first_name + '-attending'">Is {{ guestie.first_name }} attending? <span class="color-state-danger">(*)</span></label>

												<select required v-model="guestie.attending" :id="'guest-' + guestie.first_name + '-attending'">
													<option>Joyfully accepts</option>
													<option>Regretfully declines</option>
												</select>
											</div>

											<div v-if="typeof guestie.child === 'undefined'" class="col-12 col-xl">
												<label :for="'guest-' + guestie.first_name + '-main'">What would {{ guestie.first_name }} like as their main? <span class="color-state-danger">(*)</span></label>

												<select required v-model="guestie.main" :id="'guest-' + guestie.first_name + '-main'">
													<option value="null" selected>-- Select your main --</option>
													<option>Vegetable Wellington</option>
													<option>Sirloin Steak</option>
												</select>
											</div>

											<div v-if="typeof guestie.child !== 'undefined' && 1 === guestie.child" class="col-12 mt-3 flow-tight">
												<p class="font-small weight-medium">Childrens menu:</p>

												<ul class="font-small p-0 m-0" role="list">
													<li>Starter: <span class="weight-medium">Fried mozerella sticks with garlic mayonnaise</span></li>
													<li>Main: <span class="weight-medium">Sausage & tomato pasta base with mozerella</span></li>
													<li>Dessert: <span class="weight-medium">Ice cream selection with wafers.</span></li>
												</ul>

												<div>
													<label for="">Does {{ guestie.first_name }} have any allergies or dietary requirements?</label>
													<textarea name="guest-dietary" id="" cols="30" rows="10"></textarea>
												</div>
											</div>

											<div v-if="'null' !== guestie.main" class="col-12 mt-2">
												<label for="">Does {{ guestie.first_name }} have any allergies or dietary requirements?</label>

												<textarea name="guest-dietary" id="" cols="30" rows="10"></textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
						</fieldset>

						<fieldset v-if="typeof guest.group !== 'undefined' && Object.values( guest.group ).length > 0">
							<legend>
								Music
							</legend>

							<p class="description font-small mb-3">Let us know a song you'd like our DJ to play.</p>

							<div class="flow-medium">
								<div class="row">
									<div class="col-12 col-lg-6">
										<label for="song_artist">
											<span>Artist</span>
										</label>

										<input @input="sanitize_artist" type="text" v-model="guest.song_artist" id="song_artist">
									</div>

									<div class="col-12 col-lg-6">
										<label for="song_title">
											<span>Song title</span>
										</label>

										<input @input="sanitize_song_title" type="text" v-model="guest.song_title" id="song_title">
									</div>
								</div>
							</div>
						</fieldset>

						<fieldset v-if="typeof guest.group !== 'undefined' && Object.values( guest.group ).length > 0" class="flow-medium">
							<legend>
								Any questions?
							</legend>

							<label for="guest-notes">Ask us anything you may need to know.</label>
							<textarea v-model="guest.notes" id="guest-notes" cols="30" rows="10"></textarea>
						</fieldset>

						<div v-if="null !== message">
							<div :class="message.classList">
								<p class="font-base">{{ message.text }}</p>
							</div>
						</div>

						<button v-if="typeof guest.group !== 'undefined' && Object.values( guest.group ).length > 0" type="submit">Send RSVP</button>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import data from '@/assets/data/guestlist.json';

	export default {
		name: 'RSVP',

		data() {
			return {
				guestlist: data,
				guest: [],
				message: null,
				guest_found: null,
				loading: null
			}
		},

		methods: {
			find_guest: function() {
				if ( ( this.guest.first_name && this.guest.first_name.length > 2 ) && ( this.guest.surname && this.guest.surname.length > 2 ) ) {
					this.loading = true;

					let found_guest = this.guestlist.find( guest => {
						return ( this.sanitize_param( guest.first_name ) === this.sanitize_param( this.guest.first_name ) && this.sanitize_param( guest.surname ) === this.sanitize_param( this.guest.surname ) )
					} );

					if ( typeof found_guest === 'undefined' ) {
						this.loading = false;
						this.guest_found = false;

						return false;
					}

					// cache the guest.
					this.guest = found_guest;
					this.guest_found = true;
					this.guest.email = null;

					// group stuff.
					this.guest.group = [];
					this.guest.group = this.guestlist.filter( guest => found_guest.group_number === guest.group_number );

					// loop through the group and add default states.
					this.guest.group.forEach( ( guest, index ) => {
						// open state.
						if ( 0 === index ) { 
							guest.open = true;
						} else { 
							guest.open = false; 
						}

						guest.attending = 'Joyfully accepts';
						guest.main = 'null';
					});

					this.loading = false;
				}
			},

			reset: function() {
				this.guest = [];
				this.guest_found = null;
			},

			sanitize_param: function( param ) {
				return param.replace(/\s/g, "").toLowerCase();
			},

			encode( data ) {
				return Object.keys(data)
					.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
					.join("&")
			},

			rsvp: function( ) {
				let response = {};

				response.email = this.guest.email;
				response.name = this.guest.first_name + ' ' + this.guest.surname;
				response.notes = this.guest.notes;
				response.song_artist = this.guest.song_artist;
				response.song_title = this.guest.song_title;

				this.guest.group.forEach( person => {
					response[ person.first_name + ' ' + person.surname ] = {};
					response[ person.first_name + ' ' + person.surname ].attending = person.attending;
					response[ person.first_name + ' ' + person.surname ].main = person.main;
					response[ person.first_name + ' ' + person.surname ].dietary = person.dietary;
				});

				fetch("https://rsvp.naomiandmatty.co.uk/index.php", {
					method: "POST",
					headers: { 
						"Content-Type": "application/json" 
					},
					body: JSON.stringify( response )
				})
				.then(response => {
					if ( 202 === response.status || 200 === response.status ) {
						this.$router.push( { name: 'Thanks' } );
					}
				})
				.catch(() => {
					this.message = { 'classList' : 'color-state-danger weight-medium', 'text': 'Sorry there has been an error, please try again.' };
				})
			},	

			sanitize_artist: function( event ) {
				let banned_artists = [
					'garry glitter',
					'gary glitter',
					'ed sheeran',
					'arctic monkeys'
				];

				banned_artists.forEach( artist => {
					if ( this.sanitize_param( event.srcElement.value ).indexOf( this.sanitize_param( artist ) ) >= 0 ) {
						let div = document.createElement('div');
						div.id = 'songSan';
						div.classList = 'font-small weight-bold color-state-danger';
						div.innerHTML = 'How\'s about no!';
						event.srcElement.parentNode.append( div );

						event.srcElement.value = '';

						setTimeout( () => {
							if ( document.getElementById('songSan') ) {
								document.getElementById('songSan').parentNode.removeChild(document.getElementById('songSan'));
							}
						}, 2000 );
					}
				} );
			},

			sanitize_song_title: function( event ) {
				let banned_songs = [
					'the shape of you',
					'shape of you',
				];

				banned_songs.forEach( song => {
					if ( this.sanitize_param( event.srcElement.value ).indexOf( this.sanitize_param( song ) ) >= 0 ) {
						let div = document.createElement('div');
						div.id = 'songSan';
						div.classList = 'font-small weight-bold color-state-danger';
						div.innerHTML = 'How\'s about no!';
						event.srcElement.parentNode.append( div );

						event.srcElement.value = '';

						setTimeout( () => {
							if ( document.getElementById('songSan') ) {
								document.getElementById('songSan').parentNode.removeChild(document.getElementById('songSan'));
							}
						}, 2000 );
					}
				} );
			}
		}
	}
</script>

<style lang="scss" scoped>
	.times {
		width: 15px;
		height: 15px;
		position: relative;
		display: block;
		transition: transform 333ms;

		&:before, 
		&:after {
			content: '';
			position: absolute;
			background-color: currentColor;
		}

		&:before {
			width: 100%;
			height: 2px;
			left: 0;
			top: 50%;
			transform: translateY( -50% );
		}

		&:after {
			height: 100%;
			width: 2px;
			left: 50%;
			transform: translateX( -50% );
		}

		&.guest-open {
			transform: rotate( 45deg );
		}
	}

	.guest-toggle {
		width: 100%;
	}

	.guest-row {
		&:not(:last-child) {
			padding-bottom: 5px;
			border-bottom: 1px solid var( --mono-dark );
		}
	}
</style>