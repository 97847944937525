<template>
	<section class="hero bg-mono-light">
		<div class="row g-0">
			<div class="col-12 col-xl-6 row g-0">
				<div class="flow-tight p-5 p-xl-7 p-xxxl-9 mt-auto hero-copy">
					<p class="text-uppercase font-lead family-heading color-mono-darkest tracking-default">
						Friday 24th March 2023
					</p>

					<h1 class="font-largest family-heading color-mono-darkest text-uppercase tracking-default">
						Naomi &amp; Matt
					</h1>

					<p class="signoff text-uppercase font-mid family-body color-mono-darker tracking-loose">
						Are getting married
					</p>
					
					<inline-svg class="hero-svg fill-mono-dark" viewBox="0 0 343.76 639.082" :src="require( '@/assets/svgs/flower-hero.svg' )" />
				</div>
			</div>

			<div class="col-12 col-xl-6">
				<img class="hero-img" loading="lazy" :src="require( '../assets/images/hero.jpg' )" alt="Yo">
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Hero'
	}
</script>

<style lang="scss" scoped>
	.hero,
	.hero > .row,
	.hero > .row > * {
		max-height: 85vh;

		@include media-breakpoint-up(xl) {
			max-height: 100vh;
		}
	}

	.hero {
		position: relative;

		@include media-breakpoint-down(xl) {
			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgb(0,0,0);
				background: linear-gradient(45deg, rgba(0,0,0,.66) 0%, rgba(0,0,0,0) 100%);
			}
		}

		.signoff {
			letter-spacing: 0.225rem;
		}
	}

	.hero-copy {
		position: absolute;
		bottom: 0;

		@include media-breakpoint-down(xl) {
			.family-heading, .family-body {
				color: var( --mono-lightest );
			}
		}
		
		> *:not(svg) {
			position: relative;
			z-index: 1;
		}

		@include media-breakpoint-up(xl) {
			position: relative;
		}
	}

	.hero-svg {
		position: absolute;
		z-index: 0;
		bottom: -5vw;
		left: -5vw;
		width: auto;
		height: 50vh;
		opacity: 0.66;
	}

	.hero-img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
</style>