<template>
	<section class="gifts bg-mono-lightest">
		<div class="gifts-inner py-9">
			<div class="container flow-loose">
				<h2 class="family-heading font-large text-center">
					Gifts
				</h2>

				<div class="clamp-medium m-auto">
					<p>If you'd like to gift us something, we'd appreciate a contribution to our honeymoon, or home improvements.</p>
				</div>
			</div>

			<inline-svg class="gifts-diamond fill-mono-base" viewBox="0 0 18.598 25" :src="require( '@/assets/svgs/diamond-filled.svg' )"></inline-svg>	
		</div>
	</section>
</template>

<script>
	export default {
		name: "Gifts"
	}
</script>

<style lang="scss" scoped>
	.gifts-inner {
		position: relative;
	}

	.gifts > .container {
		position: relative;
		z-index: 1;
	}

	.gifts-diamond {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 1;
		min-height: 400px;
		height: 120%;
		width: auto;
		opacity: 0.2;
		max-width: 90%;
	}
</style>